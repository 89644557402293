import { useMutation } from '@tanstack/react-query';

import { getAdminAuthController } from '~/_swagger';
import type { AuthResponse } from '~/_swagger/dtos';
import { useAuthStore } from '~/stores/auth';

export const useLogin = () =>
  useMutation({
    mutationFn: (request: { username: string; password: string }) =>
      getAdminAuthController().login(request),
    onSuccess: (response: AuthResponse) => {
      useAuthStore.getState().setToken(response.token);
    },
  });
