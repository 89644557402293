import { useQuery } from '@tanstack/react-query';

import { getAdminStrainController } from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { toFilter } from '~/services/strain.service';

const getStrainFilters = () => getAdminStrainController().getAdminFilter();

export const useStrainFilters = () =>
  useQuery({
    queryKey: queryKeys.strains.filters(),
    queryFn: async () => getStrainFilters(),
    select: (data) => toFilter(data),
  });
