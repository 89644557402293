import { Button, Col, Divider, Form, Input, Row, Space, Spin } from 'antd';

import { useCurrentUser } from '~/api/users/useCurrentUser';
import { User } from '~/models/user.model';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 20, span: 20 },
};

export const Profile = () => {
  const { data: currentUser } = useCurrentUser();
  const [form] = Form.useForm<User>();

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (_user: User) => {
    // Profile update logic here
  };

  return currentUser ? (
    <Row>
      <Col span={20} offset={3}>
        <Form
          {...layout}
          form={form}
          initialValues={currentUser}
          autoComplete='off'
          onFinish={onFinish}
        >
          <Divider orientation='left'>Basic Info</Divider>
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item label='Username' name='username'>
            <Input disabled />
          </Form.Item>
          <Form.Item label='Email' name='email'>
            <Input disabled />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ padding: '12px' }}>
            <Space>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
              <Button type='primary' htmlType='submit'>
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  ) : (
    <Spin />
  );
};
