import { BrandDTO, SeedOfferDTO, SeedProductDTO, VendorDTO } from '~/_swagger';
import { Brand, SeedOffer, SeedProduct, SeedProductGender, SeedProductType, Vendor } from '~/models/seed-product.model';

export const toSeedProduct = (data: SeedProductDTO): SeedProduct => ({
  id: data.id,
  title: data.title,
  strainSlug: data.strainSlug,
  brand: toBrand(data.brand),
  quantity: data.quantity,
  type: SeedProductType[data.type],
  gender: SeedProductGender[data.gender],
  attributes: data.attributes,
  minOfferPrice: data.minOfferPrice,
  offerCount: data.offerCount,
  offers: data.offers && data.offers.map(item => toOffer(item)),
})

export const toBrand = (data: BrandDTO): Brand => ({
  id: data.id,
  title: data.title,
})

export const toOffer = (data: SeedOfferDTO): SeedOffer => ({
  url: data.url,
  price: data.price,
  vendor: toVendor(data.vendor),
  deliveryTime: data.deliveryTime,
  deliveryCosts: data.deliveryCosts,
  originalPrice: data.originalPrice,
  description: data.description,
  isAvailable: data.isAvailable,
  attributes: data.attributes,
})

export const toVendor = (data: VendorDTO): Vendor => ({
  id: data.id,
  slug: data.slug,
  title: data.title,
  description: data.description,
  returnPolicy: data.returnPolicy,
  email: data.email,
  phoneNumber: data.phoneNumber,
  logoUrl: data.logoUrl,
  shopUrl: data.shopUrl,
  imprintUrl: data.imprintUrl,
  termsOfServiceUrl: data.termsOfServiceUrl,
})
