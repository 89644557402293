import { VendorDTO, VoucherDTO } from '~/_swagger';
import { Vendor, Voucher } from '~/models/vendor.model';

export const toVendor = (data: VendorDTO): Vendor => ({
  ...data,
})

export const toVoucher = (data: VoucherDTO): Voucher => ({
  ...data,
})
