import { createFileRoute } from '@tanstack/react-router';

import { VendorDetail } from '~/pages/vendors/VendorDetail';

type VendorParams = {
  id: string;
};

export const Route = createFileRoute('/_auth/vendors/$id')({
  component: VendorDetail,
  parseParams: (params): VendorParams => ({
    id: params.id,
  }),
  validateSearch: undefined,
});
