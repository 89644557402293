import { createFileRoute, Outlet } from '@tanstack/react-router';

import { MainLayout } from '~/components/layout/MainLayout';

export const Route = createFileRoute('/_auth')({
  component: () => (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ),
});
