import { DefaultOptions, QueryClient } from '@tanstack/react-query';

import { GetStrainsParams } from '~/api/strains/useStrains';
import { StrainPropTypeEnum } from '~/models/strain.model';
import { GetVendorsParams } from '~/api/vendors/useVendors';

const queryConfig: DefaultOptions = {
  queries: {
    throwOnError: true,
    refetchOnWindowFocus: true,
    staleTime: 10000,
    retry: false,
  },
};

export const queryClient = new QueryClient({ defaultOptions: queryConfig });

export const queryKeys = {
  strainProps: {
    all: ['strainProps'] as const,
    lists: (type: StrainPropTypeEnum) => [...queryKeys.strainProps.all, 'list', type] as const,
  },
  strains: {
    all: ['strains'] as const,
    lists: () => [...queryKeys.strains.all, 'list'] as const,
    details: () => [...queryKeys.strains.all] as const,
    detail: (id: string) => [...queryKeys.strains.details(), id] as const,
    filters: () => [...queryKeys.strains.all, 'filters'] as const,
    listslWithParams: (params?: GetStrainsParams) =>
      [...queryKeys.strains.lists(), { params }] as const,
  },
  currentUser: {
    all: ['currentUser'] as const,
  },
  users: {
    all: ['users'] as const,
    lists: () => [...queryKeys.users.all, 'list'] as const,
  },
  seedProducts: {
    all: ['seedProducts'] as const,
    lists: () => [...queryKeys.seedProducts.all, 'list'] as const,
    details: () => [...queryKeys.seedProducts.all] as const,
    detail: (id: string) => [...queryKeys.seedProducts.details(), id] as const,
  },
  vendors: {
    all: ['vendors'] as const,
    lists: () => [...queryKeys.vendors.all, 'list'] as const,
    details: () => [...queryKeys.vendors.all] as const,
    detail: (id: string) => [...queryKeys.vendors.details(), id] as const,
    listslWithParams: (params?: GetVendorsParams) =>
      [...queryKeys.vendors.lists(), { params }] as const,
  },
};
