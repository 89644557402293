import { ErrorComponentProps, useNavigate } from '@tanstack/react-router';
import { Button, Result } from 'antd';

import { queryClient } from '~/lib/react-query';
import { ErrorMessage } from '~/models/error.model';

export const ErrorFallback = ({ error, reset }: ErrorComponentProps) => {
  const navigate = useNavigate();

  switch (error.toString()) {
    case ErrorMessage.E401:
      return (
        <Result
          status='403'
          title='401'
          subTitle='Login again.'
          extra={
            <Button
              type='primary'
              onClick={() => {
                navigate({ to: '/login' });
                queryClient.clear();
              }}
            >
              Login
            </Button>
          }
        />
      );
    case ErrorMessage.E403P:
      return (
        <Result
          status='403'
          title='403'
          subTitle='Sorry, you are not authorized to access this page.'
          extra={
            <Button type='primary' onClick={reset}>
              Back to home
            </Button>
          }
        />
      );
    default:
      return (
        <Result
          status='500'
          title='500'
          subTitle='Sorry, something went wrong.'
          extra={
            <Button type='primary' onClick={reset}>
              Back to home
            </Button>
          }
        />
      );
  }
};
