import { KeyOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useNavigate } from '@tanstack/react-router';
import { Avatar, Dropdown, Layout, MenuProps, Row, Space, Typography } from 'antd';

import { useCurrentUser } from '~/api/users/useCurrentUser';
import { useAuthStore } from '~/stores/auth';
import { COLORS } from '~/styles/colors';

const { Header: AntHeader } = Layout;
const { Text } = Typography;

export const Header = () => {
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const { data: currentUser } = useCurrentUser();

  const handleLogout = () => {
    logout();
    navigate({ to: '/login' });
  };

  const menuItems: MenuProps['items'] = [
    {
      label: <Link to={'/profile'}>Your Profile</Link>,
      icon: <UserOutlined />,
      key: 'profile',
    },
    {
      label: <Link to={'/change-password'}>Change Password</Link>,
      icon: <KeyOutlined />,
      key: 'change-password',
    },
    {
      type: 'divider',
    },
    {
      label: 'Log out',
      icon: <LogoutOutlined />,
      key: 'logout',
      danger: true,
      onClick: handleLogout,
    },
  ];

  return (
    <AntHeader style={{ position: 'fixed', top: 0, zIndex: 1, width: '100%', padding: '0 24px' }}>
      <Row align='middle' justify='space-between'>
        <Link to={'/'}>
          <Text style={{ color: COLORS.secondary50, fontSize: '18px', fontWeight: 500 }}>
            Kushberg Admin
          </Text>
        </Link>
        <Dropdown menu={{ items: menuItems }} trigger={['hover']} placement='bottomRight'>
          <Space style={{ cursor: 'pointer' }}>
            <Avatar icon={<UserOutlined />} style={{ backgroundColor: COLORS.primary50 }}>
              {currentUser?.username.charAt(0).toUpperCase()}
            </Avatar>
            <Text style={{ color: COLORS.secondary50 }}>{currentUser?.username}</Text>
          </Space>
        </Dropdown>
      </Row>
    </AntHeader>
  );
};
