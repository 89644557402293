import { useQuery } from '@tanstack/react-query';

import { getAdminStrainController } from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { toPagination } from '~/services/pagination.service';
import { toStrain } from '~/services/strain.service';

export type GetStrainsParams = {
  page?: number;
  pageSize?: number;
  filters?: string[];
  query?: string;
};

const getStrains = (request?: GetStrainsParams) => getAdminStrainController().findAllAdminStrains(request);

export const useStrains = (request?: GetStrainsParams) =>
  useQuery({
    queryKey: queryKeys.strains.listslWithParams(request),
    queryFn: async () => getStrains(request),
    select: (data) => ({
      data: data.data.map(toStrain),
      pagination: toPagination(data),
    }),
  });
