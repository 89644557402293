import { useMutation } from '@tanstack/react-query';

import { CreateUpdateVendorRequest, getAdminVendorController } from '~/_swagger';
import { Vendor } from '~/models/vendor.model';

const toCreateUpdateVendorRequest = (vendor: Vendor): CreateUpdateVendorRequest => ({
  slug: vendor.slug,
  name: vendor.title,
  utmParameter: vendor.utmParameter,
  description: vendor.description,
  returnPolicy: vendor.returnPolicy,
  email: vendor.email,
  phoneNumber: vendor.phoneNumber,
  logoUrl: vendor.logoUrl,
  shopUrl: vendor.shopUrl,
  imprintUrl: vendor.imprintUrl,
  termsOfServiceUrl: vendor.termsOfServiceUrl,
  adCellProgramId: vendor.adCellProgramId,
  adCellProductsCsvUrl: vendor.adCellProductsCsvUrl,
  vouchers: vendor.vouchers,
});

const createUpdateVendor = (vendor: Vendor) =>
  getAdminVendorController().createUpdateVendor(vendor.id || 'new', toCreateUpdateVendorRequest(vendor));

export const useCreateUpdateVendor = () => useMutation({ mutationFn: createUpdateVendor });
