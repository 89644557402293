/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Kushberg Dealer Admin API
 * OpenAPI spec version: 1.0
 */
import type {
  BulkUpdateStrainPriceInfoDTO,
  CreateUpdateStrainRequest,
  FindAllAdminStrainsParams,
  StrainDTO,
  StrainPageDTO,
  StrainPropFilterDTO,
  Unit,
} from '~/_swagger/dtos';
import { customAxios } from '~/lib/axios';

export const getAdminStrainController = () => {
  const findOneAdminStrain = (id: string) => {
    return customAxios<StrainDTO>({ url: `/api/admin/strains/${id}`, method: 'GET' });
  };
  const updateStrain = (id: string, createUpdateStrainRequest: CreateUpdateStrainRequest) => {
    return customAxios<Unit>({
      url: `/api/admin/strains/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainRequest,
    });
  };
  const removeStrain = (id: string) => {
    return customAxios<Unit>({ url: `/api/admin/strains/${id}`, method: 'DELETE' });
  };
  const bulkUpdatePriceInfo = (bulkUpdateStrainPriceInfoDTO: BulkUpdateStrainPriceInfoDTO) => {
    return customAxios<Unit>({
      url: `/api/admin/strains/bulk-update-price-info`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: bulkUpdateStrainPriceInfoDTO,
    });
  };
  const findAllAdminStrains = (params?: FindAllAdminStrainsParams) => {
    return customAxios<StrainPageDTO>({ url: `/api/admin/strains`, method: 'GET', params });
  };
  const createStrain = (createUpdateStrainRequest: CreateUpdateStrainRequest) => {
    return customAxios<Unit>({
      url: `/api/admin/strains`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainRequest,
    });
  };
  const getAdminFilter = () => {
    return customAxios<StrainPropFilterDTO[]>({
      url: `/api/admin/strains/options/filter`,
      method: 'GET',
    });
  };
  return {
    findOneAdminStrain,
    updateStrain,
    removeStrain,
    bulkUpdatePriceInfo,
    findAllAdminStrains,
    createStrain,
    getAdminFilter,
  };
};
export type FindOneAdminStrainResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainController>['findOneAdminStrain']>>
>;
export type UpdateStrainResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainController>['updateStrain']>>
>;
export type RemoveStrainResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainController>['removeStrain']>>
>;
export type BulkUpdatePriceInfoResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainController>['bulkUpdatePriceInfo']>>
>;
export type FindAllAdminStrainsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainController>['findAllAdminStrains']>>
>;
export type CreateStrainResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainController>['createStrain']>>
>;
export type GetAdminFilterResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainController>['getAdminFilter']>>
>;
