import { useQuery } from '@tanstack/react-query';

import { getAdminProductController } from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { toSeedProduct } from '~/services/seed-product.service';

const getSeedProduct = (id: string) => getAdminProductController().getAdminProduct(id);

export const useSeedProduct = (id: string) =>
  useQuery({
    queryKey: queryKeys.seedProducts.detail(id),
    queryFn: async () => getSeedProduct(id),
    select: (data) => toSeedProduct(data),
  });
