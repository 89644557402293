import { EditOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from '@tanstack/react-router';
import { Space, Table, Tag,Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { FilterValue } from 'antd/es/table/interface';
import { Fragment, MouseEvent } from 'react';

import { useCurrentUser } from '~/api/users/useCurrentUser';
import { NumericFilterDropdown } from '~/components/table/NumericFilterDropdown';
import { SeedProduct, SeedProductGender, SeedProductType } from '~/models/seed-product.model';
import { Route as productRoute } from '~/routes/_auth/seed-products/$id';

const { Text } = Typography;

type SeedProductTableProp = {
  products?: SeedProduct[];
  isLoading?: boolean;
  onFilterChange?: (filters: Record<string, any>) => void;
  defaultFilters?: Record<string, any>;
};

export const SeedProductTable = ({
  products: data,
  isLoading,
  onFilterChange,
  defaultFilters,
}: SeedProductTableProp) => {
  const { data: currentUser } = useCurrentUser();

  const canEditSeedProduct = currentUser?.permissions?.includes('update:strains');

  const seedProductTypeOptions = Object.values(SeedProductType).map((type) => ({
    text: type,
    value: type,
  }));
  const seedProductGenderOptions = Object.values(SeedProductGender).map((gender) => ({
    text: gender,
    value: gender,
  }));

  const handleTableChange = (filters: Record<string, FilterValue | null>) => {
    const newFilters: Record<string, any> = {};

    if (filters.type && filters.type.length > 0) {
      newFilters.type = filters.type[0];
    }
    if (filters.gender && filters.gender.length > 0) {
      newFilters.gender = filters.gender[0];
    }
    if (filters.quantity && filters.quantity.length > 0) {
      try {
        const range = JSON.parse(filters.quantity[0] as string);
        if (Array.isArray(range) && range.length === 2) {
          newFilters.quantityFrom = range[0];
          newFilters.quantityTo = range[1];
        }
      } catch (e) {}
    }
    if (filters.minOfferPrice && filters.minOfferPrice.length > 0) {
      try {
        const range = JSON.parse(filters.minOfferPrice[0] as string);
        if (Array.isArray(range) && range.length === 2) {
          newFilters.minOfferPriceFrom = range[0];
          newFilters.minOfferPriceTo = range[1];
        }
      } catch (e) {}
    }
    if (filters.offerCount && filters.offerCount.length > 0) {
      try {
        const range = JSON.parse(filters.offerCount[0] as string);
        if (Array.isArray(range) && range.length === 2) {
          newFilters.offerCountFrom = range[0];
          newFilters.offerCountTo = range[1];
        }
      } catch (e) {}
    }

    if (onFilterChange) {
      onFilterChange(newFilters);
    }
  };

  const columns: ColumnsType<SeedProduct> = [
    {
      title: 'Title',
      dataIndex: ['title', 'subTitle'],
      key: 'title',
      ellipsis: true,
      width: 200,
      render: (_, { title, subTitle }) => (
        <Space direction='vertical'>
          <Text strong>{title}</Text>
          {subTitle && <Text ellipsis>{subTitle}</Text>}
        </Space>
      ),
    },
    {
      title: 'Strain Slug',
      dataIndex: 'strainSlug',
      key: 'strainSlug',
      render: (_, { id, strainSlug }) => (
        <Fragment>
          {strainSlug.map((item) => (
            <Tag key={`${id}-${item}`}>{item}</Tag>
          ))}
        </Fragment>
      ),
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      key: 'brand',
      render: (_, { brand }) => <Text>{brand.title}</Text>,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      filters: seedProductTypeOptions,
      filteredValue: defaultFilters && defaultFilters.type ? [defaultFilters.type] : null,
      render: (_, { type }) => <Text>{type}</Text>,
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      filters: seedProductGenderOptions,
      filteredValue: defaultFilters && defaultFilters.gender ? [defaultFilters.gender] : null,
      render: (_, { gender }) => <Text>{gender}</Text>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      filterDropdown: (props) => <NumericFilterDropdown minValue={1} maxValue={100} {...props} />,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue:
        defaultFilters &&
        defaultFilters.quantityFrom !== undefined &&
        defaultFilters.quantityTo !== undefined
          ? [
              JSON.stringify([
                Number(defaultFilters.quantityFrom),
                Number(defaultFilters.quantityTo),
              ]),
            ]
          : null,
      render: (_, { quantity }) => <Text>{quantity}</Text>,
    },
    {
      title: 'Min Offer Price',
      dataIndex: 'minOfferPrice',
      key: 'minOfferPrice',
      filterDropdown: (props) => (
        <NumericFilterDropdown minValue={100} maxValue={10000} {...props} />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue:
        defaultFilters &&
        defaultFilters.minOfferPriceFrom !== undefined &&
        defaultFilters.minOfferPriceTo !== undefined
          ? [
              JSON.stringify([
                Number(defaultFilters.minOfferPriceFrom),
                Number(defaultFilters.minOfferPriceTo),
              ]),
            ]
          : null,
      render: (_, { minOfferPrice }) => <Text>{minOfferPrice}</Text>,
    },
    {
      title: 'Offer Count',
      dataIndex: 'offerCount',
      key: 'offerCount',
      filterDropdown: (props) => <NumericFilterDropdown minValue={1} maxValue={10} {...props} />,
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
      filteredValue:
        defaultFilters &&
        defaultFilters.offerCountFrom !== undefined &&
        defaultFilters.offerCountTo !== undefined
          ? [
              JSON.stringify([
                Number(defaultFilters.offerCountFrom),
                Number(defaultFilters.offerCountTo),
              ]),
            ]
          : null,
      render: (_, { offerCount }) => <Text>{offerCount}</Text>,
    },
    {
      align: 'center',
      key: 'action',
      width: 50,
      render: (_, record) =>
        canEditSeedProduct && (
          <Space size='middle'>
            <Link
              to={productRoute.to}
              params={{ id: record.id }}
              onClick={(e: MouseEvent) => e.stopPropagation()}
            >
              <EditOutlined />
            </Link>
          </Space>
        ),
    },
  ];

  return (
    <Table<SeedProduct>
      columns={columns}
      rowKey='id'
      bordered
      dataSource={data}
      pagination={false}
      loading={isLoading}
      size='small'
      onChange={(_, filters) => handleTableChange(filters)}
      scroll={{ x: 800, y: 720 }}
    />
  );
};
