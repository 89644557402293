import { useNavigate } from '@tanstack/react-router';
import { Input } from 'antd';
import { Fragment } from 'react';

import { GetSeedProductsParams, useSeedProducts } from '~/api/seed-products/useSeedProducts';
import { Pagination } from '~/components/pagination/Pagination';
import { SeedProductTable } from '~/components/table/SeedProductTable';
import { Route as seedProductsRoute } from '~/routes/_auth/seed-products/index';

const { Search } = Input;

export const SeedProductOverview = () => {
  const search = seedProductsRoute.useSearch();
  const navigate = useNavigate();

  const { data, isLoading } = useSeedProducts(search as GetSeedProductsParams);

  const onPageClick = (page: number, pageSize: number) => {
    navigate({
      to: seedProductsRoute.to,
      search: {
        ...search,
        page,
        pageSize,
      },
    });
  };

  const onSearchByTitle = (query?: string) =>
    navigate({
      to: seedProductsRoute.to,
      search: {
        ...search,
        query: query,
        page: 1,
      },
    });

  const onSearchByStrainSlug = (strainSlug?: string) =>
    navigate({
      to: seedProductsRoute.to,
      search: {
        ...search,
        strainSlug: strainSlug,
        page: 1,
      },
    });

  const onFilterChange = (filters: Record<string, any>) => {
    navigate({
      to: seedProductsRoute.to,
      search: {
        page: 1,
        ...filters,
      },
    });
  };

  return (
    <Fragment>
      <div style={{ padding: 24, display: 'flex', gap: 16, alignItems: 'center' }}>
        <Search
          defaultValue={search.query}
          style={{ width: 500 }}
          placeholder='Search by title'
          allowClear
          size='large'
          onSearch={onSearchByTitle}
          enterButton
        />
        <Search
          defaultValue={search.strainSlug}
          style={{ width: 500 }}
          placeholder='Search by strain slug'
          allowClear
          size='large'
          onSearch={onSearchByStrainSlug}
          enterButton
        />
      </div>
      <SeedProductTable
        products={data?.data}
        isLoading={isLoading}
        onFilterChange={onFilterChange}
        defaultFilters={search}
      />
      {data?.pagination && <Pagination onClick={onPageClick} data={data.pagination} />}
    </Fragment>
  );
};
