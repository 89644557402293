import { useMutation } from '@tanstack/react-query';

import { getAdminVendorController } from '~/_swagger';

const deleteVendor = (id: string) => getAdminVendorController().deleteVendorById(id);

export const useDeleteVendor = () =>
  useMutation({
    mutationFn: deleteVendor,
  });
