import { create } from 'zustand';

export const AUTH_TOKEN_KEY = 'auth_token';

interface AuthStore {
  token: string | null;
  setToken: (token: string | null) => void;
  isAuthenticated: () => boolean;
  logout: () => void;
}

export const useAuthStore = create<AuthStore>((set, get) => ({
  token: localStorage.getItem(AUTH_TOKEN_KEY),
  setToken: (token) => {
    if (token) {
      localStorage.setItem(AUTH_TOKEN_KEY, token);
    } else {
      localStorage.removeItem(AUTH_TOKEN_KEY);
    }
    set({ token });
  },
  isAuthenticated: () => {
    const token = get().token;
    return Boolean(token);
  },
  logout: () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    set({ token: null });
  },
})); 