import { useMutation } from '@tanstack/react-query';

import {
  CreateUpdateSeedOfferRequest,
  CreateUpdateSeedProductRequest,
  getAdminProductController,
} from '~/_swagger';
import { SeedOffer, SeedProduct } from '~/models/seed-product.model';

const toCreateUpdateSeedProductRequest = (
  product: SeedProduct
): CreateUpdateSeedProductRequest => ({
  title: product.title,
  subTitle: product.subTitle,
  quantity: product.quantity,
  type: product.type,
  gender: product.gender,
  attributes: product.attributes,
  brandId: product.brand.id,
  offers: product.offers?.map((offer) => (toCreateUpdateSeedOfferRequest(offer))),
  strainSlugs: product.strainSlug,
});

const toCreateUpdateSeedOfferRequest = (offer: SeedOffer): CreateUpdateSeedOfferRequest => ({
  ...offer,
  vendorId: offer.vendor.id,
})

const updateSeedProduct = (product: SeedProduct) =>
  getAdminProductController().updateProduct(product.id, toCreateUpdateSeedProductRequest(product));

export const useUpdateSeedProduct = () => useMutation({ mutationFn: updateSeedProduct });
