export type Strain = {
  id: string;

  title: string;
  slug: string;
  aka?: string;
  description?: string;
  growingInfo?: string;

  [StrainPropTypeEnum.Category]?: StrainProp[];
  [StrainPropTypeEnum.Ingredient]?: StrainProp[];
  [StrainPropTypeEnum.Terpene]?: StrainProp[];

  [StrainPropTypeEnum.GrowingType]?: StrainProp;
  [StrainPropTypeEnum.GrowingDifficulty]?: StrainProp;
  [StrainPropTypeEnum.GrowingHeight]?: StrainProp;
  [StrainPropTypeEnum.GrowingYield]?: StrainProp;
  [StrainPropTypeEnum.GrowingFlowering]?: StrainProp;

  [StrainPropTypeEnum.Feeling]?: StrainProp[];
  [StrainPropTypeEnum.Negative]?: StrainProp[];
  [StrainPropTypeEnum.Flavor]?: StrainProp[];
  [StrainPropTypeEnum.HelpsWith]?: StrainProp[];

  children?: Strain[];
  parent?: Strain;
};

export type StrainProp = {
  id: string;
  value?: number;
  name: string;
  slug: string;
  icon?: string;
  type: StrainPropTypeEnum;
  strainCount?: number;
};

export enum StrainPropTypeEnum {
  Category = 'Category',
  Ingredient = 'Ingredient',
  Terpene = 'Terpene',
  GrowingType = 'GrowingType',
  GrowingDifficulty = 'GrowingDifficulty',
  GrowingHeight = 'GrowingHeight',
  GrowingYield = 'GrowingYield',
  GrowingFlowering = 'GrowingFlowering',
  Feeling = 'Feeling',
  Negative = 'Negative',
  Flavor = 'Flavor',
  HelpsWith = 'HelpsWith',
}

export type SeedLink = {
  id?: string;
  storeName: string;
  url: string;
  price: number;
  storeLogoUrl?: string;
  productTitle?: string;
};
