import { useQuery } from '@tanstack/react-query';

import { getAdminVendorController } from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { toVendor } from '~/services/vendor.service';

const getVendor = (id: string) => getAdminVendorController().findVendorById(id);

export const useVendor = (id: string) =>
  useQuery({
    queryKey: queryKeys.vendors.detail(id),
    queryFn: async () => getVendor(id),
    select: (data) => toVendor(data),
    enabled: id !== 'new',
  });
