import { useQuery } from '@tanstack/react-query';

import { getAdminVendorController } from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { toPagination } from '~/services/pagination.service';
import { toVendor } from '~/services/vendor.service';

export type GetVendorsParams = {
  page?: number;
  pageSize?: number;
  query?: string;
};

const getVendors = (request?: GetVendorsParams) => getAdminVendorController().findAllVendors(request);

export const useVendors = (request?: GetVendorsParams) =>
  useQuery({
    queryKey: queryKeys.vendors.listslWithParams(request),
    queryFn: async () => getVendors(request),
    select: (data) => ({
      data: data.data.map(toVendor),
      pagination: toPagination(data),
    }),
  });
