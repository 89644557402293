import { Link, useNavigate } from '@tanstack/react-router';
import { Button, Input, Space } from 'antd';
import { Fragment, MouseEvent } from 'react';

import { GetVendorsParams, useVendors } from '~/api/vendors/useVendors';
import { Pagination } from '~/components/pagination/Pagination';
import { VendorTable } from '~/components/table/VendorTable';
import { Route as vendorRoute } from '~/routes/_auth/vendors/$id';
import { Route as vendorsRoute } from '~/routes/_auth/vendors/index';

const { Search } = Input;

export const VendorOverview = () => {
  const search = vendorsRoute.useSearch();
  const navigate = useNavigate();

  const { data, isLoading } = useVendors(search as GetVendorsParams);

  const onPageClick = (page: number, pageSize: number) => {
    navigate({
      to: vendorsRoute.to,
      search: {
        page,
        pageSize,
      },
    });
  };

  const onSearch = (value: string) => {
    navigate({
      to: vendorsRoute.to,
      search: {
        query: value,
        page: 1,
      },
    });
  };

  return (
    <Fragment>
      <div style={{ padding: 24, display: 'flex', gap: 16, alignItems: 'center' }}>
        <Search
          defaultValue={search.query}
          style={{ width: 500 }}
          placeholder='Search by title'
          allowClear
          size='large'
          onSearch={onSearch}
          enterButton
        />
        <Space size='middle'>
          <Link
            to={vendorRoute.to}
            params={{ id: 'new' }}
            onClick={(e: MouseEvent) => e.stopPropagation()}
          >
            <Button type='primary'>
              Create Vendor
            </Button>
          </Link>
        </Space>
      </div>
      <VendorTable vendors={data?.data} isLoading={isLoading} />
      {data?.pagination && <Pagination onClick={onPageClick} data={data.pagination} />}
    </Fragment>
  );
};
