import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';

import { useSeedProduct } from '~/api/seed-products/useSeedProduct';
import { useUpdateSeedProduct } from '~/api/seed-products/useUpdateSeedProduct';
import { useVendors } from '~/api/vendors/useVendors';
import { queryKeys } from '~/lib/react-query';
import { SeedProduct, SeedProductGender, SeedProductType, Vendor } from '~/models/seed-product.model';
import { Route as seedProductRoute } from '~/routes/_auth/seed-products/$id';

const { TextArea } = Input;

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 20, span: 20 },
};

export const SeedProductDetails = () => {
  const { id } = seedProductRoute.useParams();
  const queryClient = useQueryClient();

  const { data: product, isLoading: isLoadingProduct } = useSeedProduct(id);
  const { data: vendors, isLoading: isLoadingVendors } = useVendors();
  const updateSeedProductMutation = useUpdateSeedProduct();

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm<SeedProduct>();

  const seedProductTypeOptions = Object.values(SeedProductType).map((type) => ({
    label: type,
    value: type,
  }));
  const seedProductGenderOptions = Object.values(SeedProductGender).map((gender) => ({
    label: gender,
    value: gender,
  }));

  const onFinish = (values: SeedProduct) => {
    updateSeedProductMutation.mutate(values, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Seed product updated',
        });
        queryClient.invalidateQueries({ queryKey: queryKeys.seedProducts.detail(id) });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const renderButtons = () => (
    <Form.Item {...tailLayout} style={{ padding: '12px' }}>
      <Space>
        <Button htmlType='button' onClick={onReset}>
          Reset
        </Button>
        <Button type='primary' htmlType='submit'>
          Submit
        </Button>
      </Space>
    </Form.Item>
  );

  const onReset = () => {
    form.resetFields();
  };

  if (isLoadingProduct) {
    return <Spin />;
  }

  return (
    <Row>
      {contextHolder}
      <Col span={20} offset={2}>
        <Form
          {...layout}
          form={form}
          initialValues={product}
          autoComplete='off'
          onFinish={onFinish}
        >
          {renderButtons()}
          <Divider orientation='left'>Basic Info</Divider>
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label='Title'
            name='title'
            rules={[{ required: true, message: 'Please input Title!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label='Sub Title' name='subTitle'>
            <Input />
          </Form.Item>
          <Form.Item
            label='Strain Slugs'
            name='strainSlug'
            rules={[{ required: true, message: 'Please input at least one strain slug!' }]}
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter strain slugs"
              tokenSeparators={[',']}
            />
          </Form.Item>
          <Form.Item label='EAN' name='ean'>
            <Input />
          </Form.Item>
          <Form.Item
            label='Quantity'
            name='quantity'
            validateTrigger={['onChange', 'onBlur']}
            rules={[{ required: true, message: 'Please input Quantity!' }]}
          >
            <InputNumber min={1} placeholder='value' style={{ width: 80 }} />
          </Form.Item>
          <Form.Item
            label='Type'
            name='type'
            rules={[{ required: true, message: 'Please select product type!' }]}
          >
            <Select options={seedProductTypeOptions} />
          </Form.Item>
          <Form.Item
            label='Gender'
            name='gender'
            rules={[{ required: true, message: 'Please select product gender!' }]}
          >
            <Select options={seedProductGenderOptions} />
          </Form.Item>
          <Form.Item
            label='Brand'
            name='brand'
            rules={[{ required: true, message: 'Missing Brand' }]}
            getValueProps={(value) => ({ value: value?.title })}
          >
            <Input disabled />
          </Form.Item>
          <Divider orientation='left'>Offers</Divider>
          <Form.List name='offers'>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field) => (
                  <Space
                    key={`${field.key}-${field.name}`}
                    direction='vertical'
                    style={{
                      display: 'block',
                      marginBottom: 24,
                      padding: 12,
                      border: '1px solid #f0f0f0',
                      borderRadius: 4,
                    }}
                  >
                    <Divider>Offer #{field.name + 1}</Divider>
                    <Form.Item
                      {...field}
                      label='Vendor'
                      name={[field.name, 'vendor']}
                      key={`${field.key}-vendor`}
                      rules={[{ required: true, message: 'Missing Vendor' }]}
                      getValueProps={(value) => ({ value: value?.id })}
                    >
                      <Select
                        placeholder="Select Vendor"
                        loading={isLoadingVendors}
                        onChange={(value) => {
                          const selectedVendor = vendors?.data?.find(v => v.id === value);
                          if (selectedVendor) {
                            const currentValues = form.getFieldsValue();
                            if (currentValues.offers && Array.isArray(currentValues.offers)) {
                              const offers = [...currentValues.offers];
                              offers[field.name].vendor = selectedVendor;
                              form.setFieldsValue({ ...currentValues, offers });
                            }
                          }
                        }}
                      >
                        {vendors?.data?.map((vendor: Vendor) => (
                          <Select.Option key={vendor.id} value={vendor.id}>
                            {vendor.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='URL'
                      name={[field.name, 'url']}
                      key={`${field.key}-url`}
                      rules={[{ required: true, message: 'Missing URL' }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Price'
                      name={[field.name, 'price']}
                      key={`${field.key}-price`}
                      rules={[
                        { required: true, message: 'Missing Price' },
                        { type: 'number', min: 1, message: 'Price must be positive' },
                      ]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Original Price'
                      name={[field.name, 'originalPrice']}
                      key={`${field.key}-originalPrice`}
                      rules={[
                        { type: 'number', min: 1, message: 'Price must be positive' },
                        {
                          validator: (_, value) => {
                            const offers = form.getFieldValue('offers') || [];
                            const currentOffer = offers[field.name];
                            const price = currentOffer?.price;
                            if (value !== undefined && price !== undefined && value <= price) {
                              return Promise.reject(
                                new Error('Original Price must be greater than Price')
                              );
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Availability'
                      name={[field.name, 'isAvailable']}
                      key={`${field.key}-isAvailable`}
                      valuePropName='checked'
                      rules={[{ required: true, message: 'Missing Availability' }]}
                    >
                      <Checkbox />
                    </Form.Item>
                    <Divider orientation='left'>Optional Fields</Divider>
                    <Form.Item
                      {...field}
                      label='Delivery Time'
                      name={[field.name, 'deliveryTime']}
                      key={`${field.key}-deliveryTime`}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Delivery Costs'
                      name={[field.name, 'deliveryCosts']}
                      key={`${field.key}-deliveryCosts`}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      label='Description'
                      name={[field.name, 'description']}
                      key={`${field.key}-description`}
                    >
                      <TextArea rows={3} />
                    </Form.Item>
                    <Button
                      type='dashed'
                      onClick={() => remove(field.name)}
                      icon={<MinusCircleOutlined />}
                    >
                      Remove Offer
                    </Button>
                  </Space>
                ))}
                <Form.Item>
                  <Button type='dashed' onClick={() => add()} icon={<PlusOutlined />}>
                    Add Offer
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
          {renderButtons()}
        </Form>
      </Col>
    </Row>
  );
};
