/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Kushberg Dealer Admin API
 * OpenAPI spec version: 1.0
 */
import type {
  AuthResponse,
  ChangePasswordDto,
  ChangePasswordRequest,
  LoginRequest,
  RegistrationRequest,
  RegistrationResponse,
  UserInfoDto,
} from '~/_swagger/dtos';
import { customAxios } from '~/lib/axios';

export const getAdminAuthController = () => {
  const register = (registrationRequest: RegistrationRequest) => {
    return customAxios<RegistrationResponse>({
      url: `/api/admin/auth/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: registrationRequest,
    });
  };
  const login = (loginRequest: LoginRequest) => {
    return customAxios<AuthResponse>({
      url: `/api/admin/auth/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: loginRequest,
    });
  };
  const changePassword = (changePasswordRequest: ChangePasswordRequest) => {
    return customAxios<ChangePasswordDto>({
      url: `/api/admin/auth/change-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: changePasswordRequest,
    });
  };
  const getCurrentUser = () => {
    return customAxios<UserInfoDto>({ url: `/api/admin/auth/me`, method: 'GET' });
  };
  return { register, login, changePassword, getCurrentUser };
};
export type RegisterResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminAuthController>['register']>>
>;
export type LoginResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminAuthController>['login']>>
>;
export type ChangePasswordResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminAuthController>['changePassword']>>
>;
export type GetCurrentUserResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminAuthController>['getCurrentUser']>>
>;
