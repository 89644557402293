import { createRootRoute } from '@tanstack/react-router';
import React from 'react';

import App from '~/App';
import { ErrorFallback } from '~/components/error/ErrorFallback';

export const Route = createRootRoute({
  component: React.memo(App),
  errorComponent: ({ error, reset }) => <ErrorFallback error={error} reset={reset} />,
});
