import { Link, useRouter } from '@tanstack/react-router';
import { Layout, Menu } from 'antd';

import { useCurrentUser } from '~/api/users/useCurrentUser';
import { StrainPropTypeEnum } from '~/models/strain.model';

const { Sider: AntSider } = Layout;

export const Sider = () => {
  const router = useRouter();
  const { data: currentUser } = useCurrentUser();
  const canReadStrain = currentUser?.permissions?.includes('read:strains');

  const menuItems = [
    ...(canReadStrain
      ? [
          {
            key: 'StrainManagement',
            label: 'Strains',
            children: [
              {
                key: '/strains',
                label: <Link to={'/strains'}>All</Link>,
              },
              {
                key: '/strain-props/$strainProp',
                type: 'group',
                label: 'Strain Properties',
                children: Object.keys(StrainPropTypeEnum).map((prop) => ({
                  key: `'/strain-props/$strainProp'/${prop}`,
                  label: (
                    <Link to={'/strain-props/$strainProp'} params={{ strainProp: prop }}>
                      {prop}
                    </Link>
                  ),
                })),
              },
            ],
          },
        {
          key: 'VendorManagement',
          label: 'Vendors',
          children: [
            {
              key: '/vendors',
              label: <Link to={'/vendors'}>All</Link>,
            },
          ],
        },
        ]
      : []),
    {
      key: 'SeedProductManagement',
      label: 'Seed Products',
      children: [
        {
          key: '/seed-products',
          label: <Link to={'/seed-products'}>All</Link>,
        },
      ],
    },
  ];

  return (
    <AntSider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 64,
        bottom: 0,
      }}
    >
      <Menu
        theme='dark'
        mode='inline'
        defaultSelectedKeys={[router.state.location.pathname]}
        items={menuItems}
      />
    </AntSider>
  );
};
