/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Kushberg Dealer Admin API
 * OpenAPI spec version: 1.0
 */
import type {
  CreateUpdateVendorRequest,
  FindAllVendorsParams,
  Unit,
  VendorDTO,
  VendorPageDTO,
} from '~/_swagger/dtos';
import { customAxios } from '~/lib/axios';

export const getAdminVendorController = () => {
  const findVendorById = (id: string) => {
    return customAxios<VendorDTO>({ url: `/api/admin/vendors/${id}`, method: 'GET' });
  };
  const createUpdateVendor = (id: string, createUpdateVendorRequest: CreateUpdateVendorRequest) => {
    return customAxios<Unit>({
      url: `/api/admin/vendors/${id}`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateVendorRequest,
    });
  };
  const deleteVendorById = (id: string) => {
    return customAxios<Unit>({ url: `/api/admin/vendors/${id}`, method: 'DELETE' });
  };
  const findAllVendors = (params?: FindAllVendorsParams) => {
    return customAxios<VendorPageDTO>({ url: `/api/admin/vendors`, method: 'GET', params });
  };
  return { findVendorById, createUpdateVendor, deleteVendorById, findAllVendors };
};
export type FindVendorByIdResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminVendorController>['findVendorById']>>
>;
export type CreateUpdateVendorResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminVendorController>['createUpdateVendor']>>
>;
export type DeleteVendorByIdResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminVendorController>['deleteVendorById']>>
>;
export type FindAllVendorsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminVendorController>['findAllVendors']>>
>;
