export type SeedProduct = {
  id: string;

  title: string;
  strainSlug: string[];
  subTitle?: string;
  quantity: number;
  brand: Brand;
  type: SeedProductType;
  gender: SeedProductGender;
  attributes: SeedProductAttributes;
  offers?: SeedOffer[];
  minOfferPrice?: number;
  offerCount?: number;
}

export type Brand = {
  id: string;
  title: string;
}

export enum SeedProductType {
  Autoflower = 'Autoflower',
  Photoperiodisch = 'Photoperiodisch',
}

export enum SeedProductGender {
  Feminized = 'Feminized',
  Regular = 'Regular',
}

export type SeedProductAttributes = { [key: string]: string };

export type SeedOffer = {
  id?: string;
  url: string;
  price: number;
  vendor: Vendor;
  deliveryTime?: string;
  deliveryCosts?: string;
  originalPrice?: number;
  description?: string;
  isAvailable: boolean;
  attributes: SeedOfferAttributes;
};

export type Vendor = {
  id: string;
  slug: string;
  title: string;
  description?: string;
  returnPolicy?: string;
  email?: string;
  phoneNumber?: string;
  logoUrl?: string;
  shopUrl?: string;
  imprintUrl?: string;
  termsOfServiceUrl?: string;
};

export type SeedOfferAttributes = { [key: string]: string };
