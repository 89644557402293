/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Kushberg Dealer Admin API
 * OpenAPI spec version: 1.0
 */
import type {
  CreateUpdateStrainPropTypeRequest,
  FindAllStrainPropTypesParams,
  StrainPropTypeDTO,
  Unit,
} from '~/_swagger/dtos';
import { customAxios } from '~/lib/axios';

export const getAdminStrainPropTypeController = () => {
  const findOneStrainPropType = (id: string) => {
    return customAxios<StrainPropTypeDTO>({
      url: `/api/admin/strain-prop-types/${id}`,
      method: 'GET',
    });
  };
  const updateStrainPropType = (
    id: string,
    createUpdateStrainPropTypeRequest: CreateUpdateStrainPropTypeRequest
  ) => {
    return customAxios<Unit>({
      url: `/api/admin/strain-prop-types/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainPropTypeRequest,
    });
  };
  const removeStrainPropType = (id: string) => {
    return customAxios<Unit>({ url: `/api/admin/strain-prop-types/${id}`, method: 'DELETE' });
  };
  const findAllStrainPropTypes = (params: FindAllStrainPropTypesParams) => {
    return customAxios<StrainPropTypeDTO[]>({
      url: `/api/admin/strain-prop-types`,
      method: 'GET',
      params,
    });
  };
  const createStrainPropType = (
    createUpdateStrainPropTypeRequest: CreateUpdateStrainPropTypeRequest
  ) => {
    return customAxios<Unit>({
      url: `/api/admin/strain-prop-types`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateStrainPropTypeRequest,
    });
  };
  return {
    findOneStrainPropType,
    updateStrainPropType,
    removeStrainPropType,
    findAllStrainPropTypes,
    createStrainPropType,
  };
};
export type FindOneStrainPropTypeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainPropTypeController>['findOneStrainPropType']>>
>;
export type UpdateStrainPropTypeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainPropTypeController>['updateStrainPropType']>>
>;
export type RemoveStrainPropTypeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainPropTypeController>['removeStrainPropType']>>
>;
export type FindAllStrainPropTypesResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainPropTypeController>['findAllStrainPropTypes']>>
>;
export type CreateStrainPropTypeResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminStrainPropTypeController>['createStrainPropType']>>
>;
