import { useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';
import { Fragment } from 'react';

import { useDeleteStrainProp } from '~/api/strains/useDeleteStrainProp';
import { useStrainPropTypes } from '~/api/strains/useStrainPropTypes';
import { useUpdateStrainProp } from '~/api/strains/useUpdateStrainProp';
import { StrainPropTable } from '~/components/table/StrainPropTable';
import { queryKeys } from '~/lib/react-query';
import { StrainProp, StrainPropTypeEnum } from '~/models/strain.model';
import { Route as strainPropRoute } from '~/routes/_auth/strain-props/$strainProp';

export const StrainPropDetail = () => {
  const { strainProp } = strainPropRoute.useParams();
  const queryClient = useQueryClient();

  const [messageApi, contextHolder] = message.useMessage();

  const { data, isLoading } = useStrainPropTypes(strainProp as StrainPropTypeEnum);
  const updateStrainPropMutation = useUpdateStrainProp();
  const deleteStrainPropMutation = useDeleteStrainProp();

  const onDelete = (id: string) => {
    deleteStrainPropMutation.mutate(id, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Strain property deleted',
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.strainProps.lists(strainProp as StrainPropTypeEnum),
        });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const onSave = (value: StrainProp) => {
    updateStrainPropMutation.mutate(value, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Strain property updated',
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.strainProps.lists(strainProp as StrainPropTypeEnum),
        });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  return (
    <Fragment>
      {contextHolder}
      {data && (
        <StrainPropTable data={data} isLoading={isLoading} onDelete={onDelete} onSave={onSave} />
      )}
    </Fragment>
  );
};
