import { createFileRoute } from '@tanstack/react-router';
import { SeedProductDetails } from '~/pages/seed-products/SeedProductDetail';

type SeedProductParams = {
  id: string;
};

export const Route = createFileRoute('/_auth/seed-products/$id')({
  component: SeedProductDetails,
  parseParams: (params): SeedProductParams => ({
    id: params.id,
  }),
  validateSearch: undefined,
});
