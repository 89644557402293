/**
 * Generated by orval v7.6.0 🍺
 * Do not edit manually.
 * Kushberg Dealer Admin API
 * OpenAPI spec version: 1.0
 */
import type {
  CreateUpdateSeedProductRequest,
  GetAdminStrainProductsParams,
  SeedProductDTO,
  SeedProductPageDTO,
  StrainPriceInfoDTO,
  Unit,
} from '~/_swagger/dtos';
import { customAxios } from '~/lib/axios';

export const getAdminProductController = () => {
  const getAdminProduct = (id: string) => {
    return customAxios<SeedProductDTO>({ url: `/api/admin/products/${id}`, method: 'GET' });
  };
  const updateProduct = (
    id: string,
    createUpdateSeedProductRequest: CreateUpdateSeedProductRequest
  ) => {
    return customAxios<Unit>({
      url: `/api/admin/products/${id}`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateSeedProductRequest,
    });
  };
  const deleteProduct = (id: string) => {
    return customAxios<Unit>({ url: `/api/admin/products/${id}`, method: 'DELETE' });
  };
  const getAdminStrainProducts = (params?: GetAdminStrainProductsParams) => {
    return customAxios<SeedProductPageDTO>({ url: `/api/admin/products`, method: 'GET', params });
  };
  const createProduct = (createUpdateSeedProductRequest: CreateUpdateSeedProductRequest) => {
    return customAxios<Unit>({
      url: `/api/admin/products`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: createUpdateSeedProductRequest,
    });
  };
  const getStrainPriceInfoList = () => {
    return customAxios<StrainPriceInfoDTO[]>({ url: `/api/admin/products/info`, method: 'POST' });
  };
  return {
    getAdminProduct,
    updateProduct,
    deleteProduct,
    getAdminStrainProducts,
    createProduct,
    getStrainPriceInfoList,
  };
};
export type GetAdminProductResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminProductController>['getAdminProduct']>>
>;
export type UpdateProductResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminProductController>['updateProduct']>>
>;
export type DeleteProductResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminProductController>['deleteProduct']>>
>;
export type GetAdminStrainProductsResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminProductController>['getAdminStrainProducts']>>
>;
export type CreateProductResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminProductController>['createProduct']>>
>;
export type GetStrainPriceInfoListResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof getAdminProductController>['getStrainPriceInfoList']>>
>;
