import { Card, Space, Tag, Typography } from 'antd';

import { Strain, StrainPropTypeEnum } from '~/models/strain.model';

const { Paragraph } = Typography;

type ExpandedStrainRowProp = {
  strain: Strain;
};

export const ExpandedStrainRow = ({ strain }: ExpandedStrainRowProp) => {
  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <Card title='Description' size='small'>
        <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
          {strain.description}
        </Paragraph>
      </Card>
      <Card title='Growing Info' size='small'>
        <Tag>{strain?.[StrainPropTypeEnum.GrowingType]?.name}</Tag>
        <Tag>{strain?.[StrainPropTypeEnum.GrowingDifficulty]?.name}</Tag>
        <Tag>{strain?.[StrainPropTypeEnum.GrowingHeight]?.name}</Tag>
        <Tag>{strain?.[StrainPropTypeEnum.GrowingYield]?.name}</Tag>
        <Tag>{strain?.[StrainPropTypeEnum.GrowingFlowering]?.name}</Tag>
        <Paragraph ellipsis={{ rows: 2, expandable: true, symbol: 'more' }}>
          {strain.growingInfo}
        </Paragraph>
      </Card>
    </Space>
  );
};
