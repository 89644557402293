import { Layout } from 'antd';

const { Footer: FooterAnt } = Layout;

export const Footer = () => <FooterAnt
      style={{
        textAlign: 'center',
        position: 'fixed',
        bottom: 0,
        zIndex: 1,
        width: '100%',
      }}
    >
      Kushberg Created by ACT Digital GmbH
    </FooterAnt>;
