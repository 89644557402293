import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  getAdminProductController,
  getAdminStrainController,
  StrainPriceInfoDTO,
} from '~/_swagger';

export const useSyncStrainPrices = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      const priceInfo = await getAdminProductController().getStrainPriceInfoList();
      const updateRequest = priceInfo.map((info: StrainPriceInfoDTO) => ({
        slug: info.slug,
        minPrice: info.minPrice,
        offerCount: info.offerCount,
        productCount: info.productCount,
      }));
      await getAdminStrainController().bulkUpdatePriceInfo({ updates: updateRequest });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['strains'] });
    },
  });
};
