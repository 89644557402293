import Axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import { environment as env } from '~/environments';
import { useAuthStore } from '~/stores/auth';

export const AXIOS_INSTANCE = Axios.create({
  baseURL: env.api,
  timeout: 10000,
});

const authRequestInterceptor = async (config: InternalAxiosRequestConfig) => {
  const token = useAuthStore.getState().token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
    config.headers.Accept = 'application/json';
  }
  return config;
};

AXIOS_INSTANCE.interceptors.request.use(authRequestInterceptor);
AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const message = error.response?.data?.message || error.message;
    // If token is invalid, clear it
    if (error.response?.status === 401) {
      useAuthStore.getState().setToken(null);
    }
    return Promise.reject(message);
  }
);

export const customAxios = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token }).then(({ data }) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled by React Query');
  };

  return promise;
};
