import { createFileRoute } from '@tanstack/react-router'

import { StrainDetail } from '~/pages/strain/StrainDetail'

type StrainParams = {
  id: string
}

export const Route = createFileRoute('/_auth/strains/$id')({
  component: StrainDetail,
  parseParams: (params): StrainParams => ({
    id: params.id,
  }),
  validateSearch: undefined,
})
