import { useMutation } from '@tanstack/react-query';

import { CreateUpdateStrainRequest, getAdminStrainController } from '~/_swagger';
import { Strain, StrainPropTypeEnum } from '~/models/strain.model';

const toCreateUpdateStrainRequest = (strain: Strain): CreateUpdateStrainRequest => ({
  title: strain.title,
  slug: strain.slug,
  aka: strain.aka,
  description: strain.description,
  growingInfo: strain.growingInfo,
  terpeneId: strain?.[StrainPropTypeEnum.Terpene]?.[0].id,
  categoryId: strain?.[StrainPropTypeEnum.Category]?.[0].id,
  concentrations: strain?.[StrainPropTypeEnum.Ingredient]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  growingTypeId: strain?.[StrainPropTypeEnum.GrowingType]?.id,
  growingDifficultyId: strain?.[StrainPropTypeEnum.GrowingDifficulty]?.id,
  growingHeightId: strain?.[StrainPropTypeEnum.GrowingHeight]?.id,
  growingYieldId: strain?.[StrainPropTypeEnum.GrowingYield]?.id,
  growingFloweringId: strain?.[StrainPropTypeEnum.GrowingFlowering]?.id,

  helpsWiths: strain?.[StrainPropTypeEnum.HelpsWith]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  feelings: strain?.[StrainPropTypeEnum.Feeling]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  flavors: strain?.[StrainPropTypeEnum.Flavor]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  negatives: strain?.[StrainPropTypeEnum.Negative]?.map((p) => ({
    value: p.value ?? 0,
    strainPropTypeId: p.id,
  })),
  parentId: undefined,
});

const updateStrain = (strain: Strain) =>
  getAdminStrainController().updateStrain(strain.id, toCreateUpdateStrainRequest(strain));

export const useUpdateStrain = () => useMutation({ mutationFn: updateStrain });
