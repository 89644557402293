import { useNavigate } from '@tanstack/react-router';
import { Button, Card, Form, Input, message } from 'antd';

import { useLogin } from '~/api/auth/useLogin';

type LoginForm = {
  username: string;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const { mutate: login, isPending } = useLogin();
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  const onFinish = (values: LoginForm) => {
    login(values, {
      onSuccess: () => {
        message.success('Login successful');
        navigate({ to: redirect || '/' });
      },
      onError: (error: unknown) => {
        message.error(error as string);
      },
    });
  };

  return (
    <div style={{ 
      height: '100vh', 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center',
      background: '#f0f2f5' 
    }}>
      <Card title="Login" style={{ width: 400 }}>
        <Form
          name="login"
          layout="vertical"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password size="large" />
          </Form.Item>

          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              loading={isPending}
              block
              size="large"
            >
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};
