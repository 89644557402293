import { useQuery } from '@tanstack/react-query';

import { getAdminStrainPropTypeController } from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { StrainPropTypeEnum } from '~/models/strain.model';
import { toStrainPropType } from '~/services/strain.service';

const getStrainPropTypes = (type: StrainPropTypeEnum) =>
  getAdminStrainPropTypeController().findAllStrainPropTypes({ type });

export const useStrainPropTypes = (type: StrainPropTypeEnum) =>
  useQuery({
    queryKey: queryKeys.strainProps.lists(type),
    queryFn: async () => getStrainPropTypes(type),
    select: (data) => data.map(toStrainPropType),
  });
