import { useMutation } from '@tanstack/react-query';

import { getAdminStrainController } from '~/_swagger';

const deleteStrainProp = (id: string) => getAdminStrainController().removeStrain(id);

export const useDeleteStrainProp = () =>
  useMutation({
    mutationFn: deleteStrainProp,
  });
