import { useNavigate } from '@tanstack/react-router';
import { useNetwork } from 'ahooks';
import { Button, Layout, notification, Result, Spin } from 'antd';
import { Fragment, ReactNode, useEffect } from 'react';

import { Footer } from '~/components/layout/Footer';
import { Header } from '~/components/layout/Header';
import layoutStyles from '~/components/layout/layout.module.scss';
import { Sider } from '~/components/layout/Sider';
import { useAuthStore } from '~/stores/auth';

const { Content } = Layout;

type MainLayoutProps = {
  children: ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  const networkStatus = useNetwork();
  const navigate = useNavigate();
  const logout = useAuthStore((state) => state.logout);
  const loginWithRedirect = () => {
    logout();
    navigate({ to: '/login' });
  };
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isLoading = false;

  useEffect(() => {
    if (networkStatus.online) {
      notification.success({ message: 'You are online', placement: 'bottomRight', key: 'online' });
    } else {
      notification.error({
        message: 'You are offline',
        description: 'Check your connection.',
        placement: 'bottomRight',
        key: 'offline',
      });
    }
  }, [networkStatus.online]);

  return (
    <Fragment>
      {isLoading ? (
        <div className={layoutStyles['screenCenter']}>
          <Spin />
        </div>
      ) : isAuthenticated() ? (
        <Layout>
          <Header />
          <Layout>
            <Sider />
            <Layout style={{ marginLeft: 200 }}>
              <Content
                style={{
                  margin: '88px 16px 24px',
                  overflow: 'initial',
                  minHeight: 'calc(100vh - 112px)',
                  background: '#fff',
                }}
              >
                {children}
              </Content>
            </Layout>
          </Layout>
          <Footer />
        </Layout>
      ) : (
        <Result
          status='403'
          title='Unauthorized'
          subTitle='Please login.'
          extra={
            <Button type='primary' onClick={() => loginWithRedirect()}>
              Login
            </Button>
          }
        />
      )}
    </Fragment>
  );
};
