import { Outlet } from '@tanstack/react-router';

import { AppProvider } from '~/providers';

const App = () => {
  return (
    <AppProvider>
      <Outlet />
    </AppProvider>
  );
};

export default App;
