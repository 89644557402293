/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LoginImport } from './routes/login'
import { Route as AuthRouteImport } from './routes/_auth/route'
import { Route as AuthIndexImport } from './routes/_auth/index'
import { Route as AuthProfileImport } from './routes/_auth/profile'
import { Route as AuthChangePasswordImport } from './routes/_auth/change-password'
import { Route as AuthStrainsRouteImport } from './routes/_auth/strains/route'
import { Route as AuthVendorsIndexImport } from './routes/_auth/vendors/index'
import { Route as AuthStrainsIndexImport } from './routes/_auth/strains/index'
import { Route as AuthSeedProductsIndexImport } from './routes/_auth/seed-products/index'
import { Route as AuthVendorsIdImport } from './routes/_auth/vendors/$id'
import { Route as AuthStrainsIdImport } from './routes/_auth/strains/$id'
import { Route as AuthStrainPropsStrainPropImport } from './routes/_auth/strain-props/$strainProp'
import { Route as AuthSeedProductsIdImport } from './routes/_auth/seed-products/$id'

// Create/Update Routes

const LoginRoute = LoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => rootRoute,
} as any)

const AuthRouteRoute = AuthRouteImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AuthIndexRoute = AuthIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthProfileRoute = AuthProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthChangePasswordRoute = AuthChangePasswordImport.update({
  id: '/change-password',
  path: '/change-password',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthStrainsRouteRoute = AuthStrainsRouteImport.update({
  id: '/strains',
  path: '/strains',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthVendorsIndexRoute = AuthVendorsIndexImport.update({
  id: '/vendors/',
  path: '/vendors/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthStrainsIndexRoute = AuthStrainsIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthStrainsRouteRoute,
} as any)

const AuthSeedProductsIndexRoute = AuthSeedProductsIndexImport.update({
  id: '/seed-products/',
  path: '/seed-products/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthVendorsIdRoute = AuthVendorsIdImport.update({
  id: '/vendors/$id',
  path: '/vendors/$id',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthStrainsIdRoute = AuthStrainsIdImport.update({
  id: '/$id',
  path: '/$id',
  getParentRoute: () => AuthStrainsRouteRoute,
} as any)

const AuthStrainPropsStrainPropRoute = AuthStrainPropsStrainPropImport.update({
  id: '/strain-props/$strainProp',
  path: '/strain-props/$strainProp',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AuthSeedProductsIdRoute = AuthSeedProductsIdImport.update({
  id: '/seed-products/$id',
  path: '/seed-products/$id',
  getParentRoute: () => AuthRouteRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthRouteImport
      parentRoute: typeof rootRoute
    }
    '/login': {
      id: '/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof LoginImport
      parentRoute: typeof rootRoute
    }
    '/_auth/strains': {
      id: '/_auth/strains'
      path: '/strains'
      fullPath: '/strains'
      preLoaderRoute: typeof AuthStrainsRouteImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/change-password': {
      id: '/_auth/change-password'
      path: '/change-password'
      fullPath: '/change-password'
      preLoaderRoute: typeof AuthChangePasswordImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/profile': {
      id: '/_auth/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AuthProfileImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/': {
      id: '/_auth/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/seed-products/$id': {
      id: '/_auth/seed-products/$id'
      path: '/seed-products/$id'
      fullPath: '/seed-products/$id'
      preLoaderRoute: typeof AuthSeedProductsIdImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/strain-props/$strainProp': {
      id: '/_auth/strain-props/$strainProp'
      path: '/strain-props/$strainProp'
      fullPath: '/strain-props/$strainProp'
      preLoaderRoute: typeof AuthStrainPropsStrainPropImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/strains/$id': {
      id: '/_auth/strains/$id'
      path: '/$id'
      fullPath: '/strains/$id'
      preLoaderRoute: typeof AuthStrainsIdImport
      parentRoute: typeof AuthStrainsRouteImport
    }
    '/_auth/vendors/$id': {
      id: '/_auth/vendors/$id'
      path: '/vendors/$id'
      fullPath: '/vendors/$id'
      preLoaderRoute: typeof AuthVendorsIdImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/seed-products/': {
      id: '/_auth/seed-products/'
      path: '/seed-products'
      fullPath: '/seed-products'
      preLoaderRoute: typeof AuthSeedProductsIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_auth/strains/': {
      id: '/_auth/strains/'
      path: '/'
      fullPath: '/strains/'
      preLoaderRoute: typeof AuthStrainsIndexImport
      parentRoute: typeof AuthStrainsRouteImport
    }
    '/_auth/vendors/': {
      id: '/_auth/vendors/'
      path: '/vendors'
      fullPath: '/vendors'
      preLoaderRoute: typeof AuthVendorsIndexImport
      parentRoute: typeof AuthRouteImport
    }
  }
}

// Create and export the route tree

interface AuthStrainsRouteRouteChildren {
  AuthStrainsIdRoute: typeof AuthStrainsIdRoute
  AuthStrainsIndexRoute: typeof AuthStrainsIndexRoute
}

const AuthStrainsRouteRouteChildren: AuthStrainsRouteRouteChildren = {
  AuthStrainsIdRoute: AuthStrainsIdRoute,
  AuthStrainsIndexRoute: AuthStrainsIndexRoute,
}

const AuthStrainsRouteRouteWithChildren =
  AuthStrainsRouteRoute._addFileChildren(AuthStrainsRouteRouteChildren)

interface AuthRouteRouteChildren {
  AuthStrainsRouteRoute: typeof AuthStrainsRouteRouteWithChildren
  AuthChangePasswordRoute: typeof AuthChangePasswordRoute
  AuthProfileRoute: typeof AuthProfileRoute
  AuthIndexRoute: typeof AuthIndexRoute
  AuthSeedProductsIdRoute: typeof AuthSeedProductsIdRoute
  AuthStrainPropsStrainPropRoute: typeof AuthStrainPropsStrainPropRoute
  AuthVendorsIdRoute: typeof AuthVendorsIdRoute
  AuthSeedProductsIndexRoute: typeof AuthSeedProductsIndexRoute
  AuthVendorsIndexRoute: typeof AuthVendorsIndexRoute
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthStrainsRouteRoute: AuthStrainsRouteRouteWithChildren,
  AuthChangePasswordRoute: AuthChangePasswordRoute,
  AuthProfileRoute: AuthProfileRoute,
  AuthIndexRoute: AuthIndexRoute,
  AuthSeedProductsIdRoute: AuthSeedProductsIdRoute,
  AuthStrainPropsStrainPropRoute: AuthStrainPropsStrainPropRoute,
  AuthVendorsIdRoute: AuthVendorsIdRoute,
  AuthSeedProductsIndexRoute: AuthSeedProductsIndexRoute,
  AuthVendorsIndexRoute: AuthVendorsIndexRoute,
}

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/strains': typeof AuthStrainsRouteRouteWithChildren
  '/change-password': typeof AuthChangePasswordRoute
  '/profile': typeof AuthProfileRoute
  '/': typeof AuthIndexRoute
  '/seed-products/$id': typeof AuthSeedProductsIdRoute
  '/strain-props/$strainProp': typeof AuthStrainPropsStrainPropRoute
  '/strains/$id': typeof AuthStrainsIdRoute
  '/vendors/$id': typeof AuthVendorsIdRoute
  '/seed-products': typeof AuthSeedProductsIndexRoute
  '/strains/': typeof AuthStrainsIndexRoute
  '/vendors': typeof AuthVendorsIndexRoute
}

export interface FileRoutesByTo {
  '/login': typeof LoginRoute
  '/change-password': typeof AuthChangePasswordRoute
  '/profile': typeof AuthProfileRoute
  '/': typeof AuthIndexRoute
  '/seed-products/$id': typeof AuthSeedProductsIdRoute
  '/strain-props/$strainProp': typeof AuthStrainPropsStrainPropRoute
  '/strains/$id': typeof AuthStrainsIdRoute
  '/vendors/$id': typeof AuthVendorsIdRoute
  '/seed-products': typeof AuthSeedProductsIndexRoute
  '/strains': typeof AuthStrainsIndexRoute
  '/vendors': typeof AuthVendorsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteRouteWithChildren
  '/login': typeof LoginRoute
  '/_auth/strains': typeof AuthStrainsRouteRouteWithChildren
  '/_auth/change-password': typeof AuthChangePasswordRoute
  '/_auth/profile': typeof AuthProfileRoute
  '/_auth/': typeof AuthIndexRoute
  '/_auth/seed-products/$id': typeof AuthSeedProductsIdRoute
  '/_auth/strain-props/$strainProp': typeof AuthStrainPropsStrainPropRoute
  '/_auth/strains/$id': typeof AuthStrainsIdRoute
  '/_auth/vendors/$id': typeof AuthVendorsIdRoute
  '/_auth/seed-products/': typeof AuthSeedProductsIndexRoute
  '/_auth/strains/': typeof AuthStrainsIndexRoute
  '/_auth/vendors/': typeof AuthVendorsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/login'
    | '/strains'
    | '/change-password'
    | '/profile'
    | '/'
    | '/seed-products/$id'
    | '/strain-props/$strainProp'
    | '/strains/$id'
    | '/vendors/$id'
    | '/seed-products'
    | '/strains/'
    | '/vendors'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/login'
    | '/change-password'
    | '/profile'
    | '/'
    | '/seed-products/$id'
    | '/strain-props/$strainProp'
    | '/strains/$id'
    | '/vendors/$id'
    | '/seed-products'
    | '/strains'
    | '/vendors'
  id:
    | '__root__'
    | '/_auth'
    | '/login'
    | '/_auth/strains'
    | '/_auth/change-password'
    | '/_auth/profile'
    | '/_auth/'
    | '/_auth/seed-products/$id'
    | '/_auth/strain-props/$strainProp'
    | '/_auth/strains/$id'
    | '/_auth/vendors/$id'
    | '/_auth/seed-products/'
    | '/_auth/strains/'
    | '/_auth/vendors/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRouteRoute: typeof AuthRouteRouteWithChildren
  LoginRoute: typeof LoginRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRouteRoute: AuthRouteRouteWithChildren,
  LoginRoute: LoginRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/login"
      ]
    },
    "/_auth": {
      "filePath": "_auth/route.tsx",
      "children": [
        "/_auth/strains",
        "/_auth/change-password",
        "/_auth/profile",
        "/_auth/",
        "/_auth/seed-products/$id",
        "/_auth/strain-props/$strainProp",
        "/_auth/vendors/$id",
        "/_auth/seed-products/",
        "/_auth/vendors/"
      ]
    },
    "/login": {
      "filePath": "login.tsx"
    },
    "/_auth/strains": {
      "filePath": "_auth/strains/route.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/strains/$id",
        "/_auth/strains/"
      ]
    },
    "/_auth/change-password": {
      "filePath": "_auth/change-password.tsx",
      "parent": "/_auth"
    },
    "/_auth/profile": {
      "filePath": "_auth/profile.tsx",
      "parent": "/_auth"
    },
    "/_auth/": {
      "filePath": "_auth/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/seed-products/$id": {
      "filePath": "_auth/seed-products/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/strain-props/$strainProp": {
      "filePath": "_auth/strain-props/$strainProp.tsx",
      "parent": "/_auth"
    },
    "/_auth/strains/$id": {
      "filePath": "_auth/strains/$id.tsx",
      "parent": "/_auth/strains"
    },
    "/_auth/vendors/$id": {
      "filePath": "_auth/vendors/$id.tsx",
      "parent": "/_auth"
    },
    "/_auth/seed-products/": {
      "filePath": "_auth/seed-products/index.tsx",
      "parent": "/_auth"
    },
    "/_auth/strains/": {
      "filePath": "_auth/strains/index.tsx",
      "parent": "/_auth/strains"
    },
    "/_auth/vendors/": {
      "filePath": "_auth/vendors/index.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
