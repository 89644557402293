import { useQuery } from '@tanstack/react-query';

import { getAdminStrainController } from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { toStrain } from '~/services/strain.service';

const getStrain = (id: string) => getAdminStrainController().findOneAdminStrain(id);

export const useStrain = (id: string) =>
  useQuery({
    queryKey: queryKeys.strains.detail(id),
    queryFn: async () => getStrain(id),
    select: (data) => toStrain(data),
  });
