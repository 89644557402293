/* eslint-disable @typescript-eslint/consistent-type-definitions */
import '~/index.css';

import { createRouter, RouterProvider } from '@tanstack/react-router';
import { ConfigProvider } from 'antd';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { routeTree } from '~/routeTree.gen';
import { COLORS } from '~/styles/colors';

const router = createRouter({ routeTree });

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

ConfigProvider.config({
  theme: {
    primaryColor: COLORS.primary700,
    successColor: COLORS.success,
    infoColor: COLORS.info,
    warningColor: COLORS.warning,
    errorColor: COLORS.error,
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
