import { useQuery } from '@tanstack/react-query';

import {
  getAdminProductController, GetAdminStrainProductsGender, GetAdminStrainProductsType,
} from '~/_swagger';
import { queryKeys } from '~/lib/react-query';
import { toPagination } from '~/services/pagination.service';
import { toSeedProduct } from '~/services/seed-product.service';

export type GetSeedProductsParams = {
  page?: number;
  pageSize?: number;
  query?: string;
  brand?: string;
  type?: GetAdminStrainProductsType;
  gender?: GetAdminStrainProductsGender;
  quantityFrom?: number;
  quantityTo?: number;
  offerCountFrom?: number;
  offerCountTo?: number;
  minOfferPriceFrom?: number;
  minOfferPriceTo?: number;
};

const getSeedProducts = (request?: GetSeedProductsParams) => getAdminProductController().getAdminStrainProducts(request);

export const useSeedProducts = (request?: GetSeedProductsParams) =>
  useQuery({
    queryKey: queryKeys.strains.listslWithParams(request),
    queryFn: async () => getSeedProducts(request),
    select: (data) => ({
      data: data.data.map(toSeedProduct),
      pagination: toPagination(data),
    }),
  });
