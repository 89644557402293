import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { message, Space, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { MouseEvent } from 'react';

import { useCurrentUser } from '~/api/users/useCurrentUser';
import { useDeleteVendor } from '~/api/vendors/useDeleteVendor';
import { queryKeys } from '~/lib/react-query';
import { Vendor } from '~/models/vendor.model';
import { Route as vendorRoute } from '~/routes/_auth/vendors/$id';

const { Text } = Typography;

type VendorTableProp = {
  vendors?: Vendor[];
  isLoading?: boolean;
};

export const VendorTable = ({ vendors: data, isLoading }: VendorTableProp) => {
  const { data: currentUser } = useCurrentUser();

  const canEditStrain = currentUser?.permissions?.includes('update:strains');

  const deleteVendorMutation = useDeleteVendor();
  const queryClient = useQueryClient();

  const [messageApi] = message.useMessage();

  const onDelete = (id: string) => {
    deleteVendorMutation.mutate(id, {
      onSuccess: () => {
        messageApi.open({
          type: 'success',
          content: 'Vendor deleted',
        });
        queryClient.invalidateQueries({
          queryKey: queryKeys.vendors.lists(),
        });
      },
      onError: () => {
        messageApi.open({
          type: 'error',
          content: `something's wrong`,
        });
      },
    });
  };

  const columns: ColumnsType<Vendor> = [
    {
      title: 'Title',
      dataIndex: ['title'],
      key: 'title',
      ellipsis: true,
      width: 150,
      render: (_, { title }) => <Text strong>{title}</Text>,
    },
    {
      title: 'Vouchers',
      dataIndex: ['numOfVouchers'],
      key: 'numOfVouchers',
      ellipsis: true,
      render: (_, { numOfVouchers }) => <Text>{numOfVouchers}</Text>,
    },
    {
      title: 'Email',
      dataIndex: ['email'],
      key: 'email',
      ellipsis: true,
      render: (_, { email }) => <Text>{email}</Text>,
    },
    {
      title: 'Phone Number',
      dataIndex: ['phoneNumber'],
      key: 'phoneNumber',
      ellipsis: true,
      render: (_, { phoneNumber }) => <Text>{phoneNumber}</Text>,
    },
    {
      title: 'Logo Url',
      dataIndex: ['logoUrl'],
      key: 'logoUrl',
      ellipsis: true,
      render: (_, { logoUrl }) => <Text>{logoUrl}</Text>,
    },
    {
      title: 'Shop Url',
      dataIndex: ['shopUrl'],
      key: 'shopUrl',
      ellipsis: true,
      render: (_, { shopUrl }) => <Text>{shopUrl}</Text>,
    },
    {
      title: 'Imprint Url',
      dataIndex: ['imprintUrl'],
      key: 'imprintUrl',
      ellipsis: true,
      render: (_, { imprintUrl }) => <Text>{imprintUrl}</Text>,
    },
    {
      title: 'Terms Of Service Url',
      dataIndex: ['termsOfServiceUrl'],
      key: 'termsOfServiceUrl',
      ellipsis: true,
      render: (_, { termsOfServiceUrl }) => <Text>{termsOfServiceUrl}</Text>,
    },
    {
      align: 'center',
      key: 'action',
      width: 50,
      render: (_, record) =>
        canEditStrain && (
          <Space size='middle'>
            <Link
              to={vendorRoute.to}
              params={{ id: record.id }}
              onClick={(e: MouseEvent) => e.stopPropagation()}
            >
              <EditOutlined />
            </Link>
          </Space>
        ),
    },
    {
      align: 'center',
      key: 'action',
      width: 50,
      render: (_, record) =>
        canEditStrain && (
          <Space size='middle'>
            <DeleteOutlined onClick={() => onDelete(record.id)} />
          </Space>
        ),
    },
  ];

  return (
    <Table<Vendor>
      columns={columns}
      rowKey='id'
      bordered
      dataSource={data}
      pagination={false}
      loading={isLoading}
      size='small'
      scroll={{ x: 800, y: 720 }}
    />
  );
};
