import { Button, Col, Divider, Form, Input, message, Row, Space } from 'antd';

import { useChangePassword } from '~/api/auth/useChangePassword';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const tailLayout = {
  wrapperCol: { offset: 20, span: 20 },
};

type PasswordForm = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export const ChangePassword = () => {
  const { mutate: changePassword, isPending: isChangingPassword } = useChangePassword();
  const [form] = Form.useForm<PasswordForm>();

  const onReset = () => {
    form.resetFields();
  };

  const onFinish = (values: PasswordForm) => {
    if (values.newPassword !== values.confirmPassword) {
      message.error('New passwords do not match');
      return;
    }

    changePassword(
      {
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
      },
      {
        onSuccess: () => {
          message.success('Password changed successfully');
          form.resetFields();
        },
        onError: (error) => {
          message.error(error instanceof Error ? error.message : String(error));
        },
      }
    );
  };

  return (
    <Row>
      <Col span={20} offset={3}>
        <Form {...layout} form={form} autoComplete='off' onFinish={onFinish}>
          <Divider orientation='left'>Change Password</Divider>
          <Form.Item
            label='Current Password'
            name='currentPassword'
            rules={[{ required: true, message: 'Please input your current password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='New Password'
            name='newPassword'
            rules={[
              { required: true, message: 'Please input your new password!' },
              { min: 8, message: 'Password must be at least 8 characters!' },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label='Confirm Password'
            name='confirmPassword'
            rules={[
              { required: true, message: 'Please confirm your new password!' },
              ({ getFieldValue }) => ({
                validator: (_, value) => {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('The new passwords do not match!'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item {...tailLayout} style={{ padding: '12px' }}>
            <Space>
              <Button htmlType='button' onClick={onReset}>
                Reset
              </Button>
              <Button type='primary' htmlType='submit' loading={isChangingPassword}>
                Change Password
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
