import { useMutation } from '@tanstack/react-query';

import { CreateUpdateStrainPropTypeRequest, getAdminStrainPropTypeController } from '~/_swagger';
import { StrainProp } from '~/models/strain.model';

const toCreateUpdateStrainPropTypeDto = (data: StrainProp): CreateUpdateStrainPropTypeRequest => ({
  type: data.type,
  icon: data.icon,
  name: data.name,
  slug: data.slug,
});

const updateStrainProp = (strainProp: StrainProp) =>
  getAdminStrainPropTypeController().updateStrainPropType(
    strainProp.id,
    toCreateUpdateStrainPropTypeDto(strainProp)
  );

export const useUpdateStrainProp = () =>
  useMutation({
    mutationFn: updateStrainProp,
  });
