import { Button, Slider } from 'antd';
import { Key, useEffect, useMemo, useState } from 'react';

type NumericFilterDropdownProps = {
  minValue: number;
  maxValue: number;
  setSelectedKeys: (selectedKeys: Key[]) => void;
  selectedKeys: Key[];
  confirm: () => void;
  clearFilters?: () => void;
};

export const NumericFilterDropdown = ({
  minValue,
  maxValue,
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: NumericFilterDropdownProps) => {
  const initialRange = useMemo(() => {
    if (selectedKeys && selectedKeys[0]) {
      try {
        const parsed = JSON.parse(selectedKeys[0] as string);
        return Array.isArray(parsed) && parsed.length === 2 ? parsed : [minValue, maxValue];
      } catch (e) {
        return [minValue, maxValue];
      }
    }
    return [minValue, maxValue];
  }, [selectedKeys, minValue, maxValue]);

  const [value, setValue] = useState<number[]>(initialRange);

  useEffect(() => {
    setValue(initialRange);
  }, [initialRange]);

  const handleReset = () => {
    setValue([minValue, maxValue]);
    if (clearFilters) {
      clearFilters();
    } else {
      setSelectedKeys([]);
    }
  };

  return (
    <div style={{ padding: 8, width: 220 }}>
      <Slider
        range
        min={minValue}
        max={maxValue}
        value={value}
        onChange={(newValue) => setValue(newValue)}
      />
      <Button
        type='primary'
        onClick={() => {
          setSelectedKeys([JSON.stringify(value)]);
          confirm();
        }}
        size='small'
        style={{ marginTop: 8, width: '100%' }}
      >
        Submit
      </Button>
      <Button onClick={handleReset} size='small' style={{ marginTop: 8, width: '100%' }}>
        Reset
      </Button>
    </div>
  );
};
